import { Form } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import contactus from "../../api/contactus";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import BreadCrumb from "../../components/elements/BreadCrumb";
import FormikFrom from "../../components/elements/form/FormikFrom";
import InputField from "../../components/elements/form/InputField";
import Container from "../../components/layouts/Container";
import ModuleFooterContact from "../../components/shared/footers/modules/ModuleFooterContact";
import shopcofig from "../../config/shopconfig.json";

/*
  To initial values json
*/
const initialValues = {
  name: "",
  email: "",
  mobile: "",
  subject: "",
  message: "",
};

/*
  To form validation using yup
*/
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter value for Name")
    .matches(/^[a-zA-Z ]*$/, "Please enter valid Name"),
  mobile: Yup.string()
    .required("Please enter value for Mobile")
    .matches(/^[0-9]+$/, "Please enter valid Mobile no")
    .min(10, "Please enter minimum 10 digits Mobile no"),
  email: Yup.string().email().required("Please enter value for Email"),
  subject: Yup.string().required("Please enter value for Subject"),
  message: Yup.string().required("Please enter value for Message"),
});

const ContactUs = () => {
  const [disable, setDisable] = useState(false);
  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Contact Us",
    },
  ];
  useEffect(() => {
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 500);
    window.scrollTo(0, 0);
  }, []);

  const setSuccessMessage = () => {
    document.getElementById("contactus_successmsg").classList.add("hide");
  };

  /*
    To save contact us
  */
  const saveContactUs = async (data) => {
    setDisable(true);
    document.getElementById("errormsg").classList.add("hide");
    document.getElementById("errormsg").innerHTML = "";
    let regMobile = /^[0-9]{7,10}$/;
    if (regMobile.test(data.mobile)) {
      const res = await contactus.saveContactUs(data);
      if (res.data.status === 200) {
        setDisable(false);
        document.getElementById("contactus_successmsg").classList.remove("hide");
      } else {
        setDisable(false);
        document.getElementById("errormsg").classList.remove("hide");
        document.getElementById("errormsg").innerHTML = res.data.errors.message;
      }
    } else {
      setDisable(false);
      document.getElementById("errormsg").classList.remove("hide");
      document.getElementById("errormsg").innerHTML = "Invalid Mobile";
    }
  };

  return (
    <Container>
      <GoogleCaptcha />
      <div className="ps-page form-loader">
        {disable && (
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
        )}
        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
            <h1 className="ps-page__heading">Contact Us</h1>
          </div>
          <div className="ps-page__content ps-account ">
            <div className="row">
              <div className="col-12 col-md-4 mt-30 ">
                <div className=" card-box-shadow contactus-info ">
                  <ModuleFooterContact classname="title-font" />
                  <hr className="mt-20"></hr>
                  <p className="contactus-addres title-font">
                    <i className="fa fa-map-marker"></i>
                    <span className="pl-10">
                      {shopcofig.shop_address}
                      {shopcofig.shop_city} {shopcofig.shop_pincode}
                      {shopcofig.shop_state},{shopcofig.shop_country}
                    </span>
                  </p>
                  <p className="contactus-addres title-font add-new">
                    <i className="fa fa-map-marker"></i>
                    <span className="pl-10">
                      CDS - Charliee Day To Day Stores, Shop No. 1, 2, 3, 4, Opp. Ashadham School, Koparli Road, Vapi
                      Station Road, . Vapi (East), Gujarat, India
                    </span>
                  </p>
                  <hr className="mt-20"></hr>
                  <p className="contactus-addres">
                    <div className="ps-product__sharing">
                      <ul className="ps-social ps-social--color">
                        {shopcofig.facebook && (
                          <li>
                            <a className="ps-social__link facebook" href={shopcofig.facebook}>
                              <i className="fa fa-facebook"> </i>
                              <span className="ps-tooltip">Facebook</span>
                            </a>
                          </li>
                        )}
                        {shopcofig.youtube && (
                          <li>
                            <a className="ps-social__link youtube contactus-youtube" href={shopcofig.youtube}>
                              <i className="fa fa-youtube-play"></i>
                              <span className="ps-tooltip">Youtube</span>
                            </a>
                          </li>
                        )}

                        {shopcofig.instagram && (
                          <li>
                            <a className="ps-social__link instagram contactus-instagram" href={shopcofig.instagram}>
                              <i className="fa fa-instagram"></i>
                              <span className="ps-tooltip">Instagram</span>
                            </a>
                          </li>
                        )}

                        {shopcofig.pinterest && (
                          <li>
                            <a className="ps-social__link pinterest" href={shopcofig.pinterest}>
                              <i className="fa fa-pinterest-p"></i>
                              <span className="ps-tooltip">Pinterest</span>
                            </a>
                          </li>
                        )}
                        {shopcofig.linkedin && (
                          <li>
                            <a className="ps-social__link linkedin" href={shopcofig.linkedin}>
                              <i className="fa fa-linkedin"></i>
                              <span className="ps-tooltip">Linkedin</span>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-8 mt-10 mb-50 ">
                <div className="ps-form--review pt-0 conatctus-form-padding">
                  <FormikFrom
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                      saveContactUs(values);
                      resetForm("");
                    }}
                  >
                    <Form className=" card-box-shadow bn width-100 p-30">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <InputField
                            label={"Name *"}
                            name={"name"}
                            type={"text"}
                            placeholder={"Name"}
                            maxLength={"50"}
                            id={"name"}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <InputField
                            label={"Email *"}
                            name={"email"}
                            type={"email"}
                            placeholder={"Email"}
                            maxLength={"50"}
                            id={"email"}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-md-6">
                          <InputField
                            label={"Mobile *"}
                            name={"mobile"}
                            type={"text"}
                            placeholder={"Mobile"}
                            maxLength={"10"}
                            id={"mobile"}
                            onKeyUp={() => {
                              document.getElementById("errormsg").classList.add("hide");
                              document.getElementById("errormsg").innerHTML = "";
                            }}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <InputField
                            label={"Subject *"}
                            name={"subject"}
                            type={"text"}
                            placeholder={"Subject"}
                            maxLength={"50"}
                            id={"subject"}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <InputField
                            label={"Message *"}
                            name={"message"}
                            placeholder={"Your Message"}
                            maxLength={"500"}
                            component="textarea"
                            classname="ps-form__textarea"
                            rows="3"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 color-green hide font-size-14 mb-20" id="contactus_successmsg">
                          Your details have been submitted. Our support team will contact you in next 24 hours
                        </div>
                      </div>

                      <span className="color-red hide" id="errormsg"></span>
                      <div className="ps-form__submit mt-10">
                        <button
                          onClick={setSuccessMessage}
                          className={
                            disable ? "ps-btn ps-btn--warning width-120 disabled" : "ps-btn ps-btn--warning width-120"
                          }
                        >
                          Save
                        </button>
                      </div>
                    </Form>
                  </FormikFrom>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row ml-10 mr-10 mt-25">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14938.114143159108!2d72.9244416!3d20.6073026!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0c32778a0d2b3%3A0x30da05045751a52b!2sCDS%20-%20Charliee%20Day%20To%20Day%20Stores!5e0!3m2!1sen!2sin!4v1677474601275!5m2!1sen!2sin"
            width="600"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </Container>
  );
};

export default ContactUs;
