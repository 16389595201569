import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";

import home from "../../api/home";
import Container from "../../components/layouts/Container";
import HomeThreeTopBanners from "../../components/partials/homepages/home-3/HomeThreeTopBanners";
import PopularCategoriesTwo from "../../components/partials/homepages/sections/PopularCategoriesTwo";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import config from "../../config/config.json";
import useEcomerce from "../../hooks/useEcomerce";
import Banner from "./Banner";
import BestSellProduct from "./BestSellProduct";
import CategoryProduct from "./CategoryProduct";
import CustomerStory from "./CustomerStory";
import FeaturedProduct from "./FeaturedProduct";
import Security from "./Security";
import WhyUs from "./WhyUs";

const Home = ({ ecomerce }) => {
  const [homedata, setHomeData] = useState(null);
  const [showad, setShowAdd] = useState(false);
  const [timerCount, setTimer] = useState(5);

  const { currencyrate, getcurrencyRate } = useEcomerce();

  let imagepath = config.apiUrl + config.image_path;

  useEffect(() => {
    if (timerCount <= 0) {
      setShowAdd(true);
    }
    if (timerCount === 5) {
      countdown();
    }
  }, [timerCount]);

  useEffect(() => {
    getcurrencyRate();
    getHomeData();
  }, [ecomerce]);

  /*
    To get home data
  */
  const getHomeData = async () => {
    const responseData = await home.getHomeData();
    console.log(responseData);
    if (responseData) {
      setHomeData(responseData.data.payload);
    }
  };

  /*
    To model close
  */
  const handleClose = () => {
    setShowAdd(false);
  };

  /*
    To count down
  */
  const countdown = () => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        return lastTimerCount - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  };

  return (
    <Container note={homedata} product={{ pagetitle: "CDS – Charliee Day To Day Stores" }}>
      <GoogleCaptcha />
      <div className="form-loader homediv">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        {homedata && <HomeThreeTopBanners banner={homedata.homedetail} />}
        <div className="home-background-image">
          {homedata && homedata.homecategory && (
            <PopularCategoriesTwo category={homedata.homecategory} homedetail={homedata.homedetail} />
          )}
          {homedata && homedata.bestsellproducts && (
            <BestSellProduct
              products={homedata.bestsellproducts}
              currencyrate={currencyrate}
              homedetail={homedata.homedetail}
            />
          )}
          {homedata && homedata.displaysitecategory && (
            <CategoryProduct products={homedata.displaysitecategory} currencyrate={currencyrate} />
          )}
          {homedata && <CustomerStory customerreview={homedata.customerreview} homedetail={homedata.homedetail} />}
          {homedata && <WhyUs homedetail={homedata.homedetail} />}
          {homedata && (
            <FeaturedProduct
              featured={homedata.featuredproducts}
              currencyrate={currencyrate}
              homedetail={homedata.homedetail}
            />
          )}
          {homedata && <Banner offer={homedata.homedetail} />}

          <div className="row instafeed ml-0 mr-0 category-fig">
            <div className="col-6 col-md-3 p-10">
              <a href="https://www.instagram.com/p/CuWyS5DoFNZ/" target="_blank">
                <div className="grid">
                  <figure class="effect-goliath" style={{ width: "100%" }}>
                    <img src="/static/img/1.jpg" alt="" style={{ border: "1px solid lightgray" }} />
                  </figure>
                </div>
              </a>
            </div>
            <div className="col-6 col-md-3 p-10">
              <a href="https://www.instagram.com/p/Cuy2scLoEIZ/" target="_blank">
                <div className="grid">
                  <figure class="effect-goliath" style={{ width: "100%" }}>
                    <img src="/static/img/2.jpg" alt="" style={{ border: "1px solid lightgray" }} />
                  </figure>
                </div>
              </a>
            </div>
            <div className="col-6 col-md-3 p-10">
              <a href="https://www.instagram.com/p/CutSBgFohMM/" target="_blank">
                <div className="grid">
                  <figure class="effect-goliath" style={{ width: "100%" }}>
                    <img src="/static/img/3.jpg" alt="" style={{ border: "1px solid lightgray" }} />
                  </figure>
                </div>
              </a>
            </div>
            <div className="col-6 col-md-3 p-10">
              <a href="https://www.instagram.com/p/CtJefqyoFGu/" target="_blank">
                <div className="grid">
                  <figure class="effect-goliath" style={{ width: "100%" }}>
                    <img src="/static/img/4.jpg" alt="" style={{ border: "1px solid lightgray" }} />
                  </figure>
                </div>
              </a>
            </div>
          </div>
          <div style={{ backgroundColor: "#ffeeee" }}>
            <a
              href="https://www.instagram.com/cdsstores/"
              target="_blank"
              style={{ justifyContent: "center", display: "flex", paddingBottom: 50 }}
            >
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  padding: 10,
                  border: "1px solid gray",
                  width: 250,
                  borderRadius: 10,
                  backgroundColor: "white",
                }}
              >
                <img src="/static/img/Instagram-Icon.png" alt="" style={{ height: 50 }} />
                <p style={{ color: "black", marginLeft: 20 }}>Get social with us</p>
              </div>
            </a>
          </div>

          {homedata && <Security />}
        </div>

        {homedata && homedata.homedetail && homedata.homedetail.displaysalepopup === 1 && (
          <Modal show={showad} onHide={handleClose} className="modal-width popup-model" size={"lg"}>
            <Modal.Body>
              <div className="ps-form--review">
                <div className="row popupmodel">
                  <div className="col-12 col-md-5 text-left align-self-center pl-40 popup-content">
                    <h4>{homedata.homedetail.popuptitle}</h4>
                    <p className="pb-40 title-font">{homedata.homedetail.popupdesc}</p>
                    {homedata.homedetail.popupdisplaybutton === 1 && (
                      <a className="ps-btn ps-btn--warning width-200 mb-20" href={homedata.homedetail.popupdisplayurl}>
                        {homedata.homedetail.popupdisplaytitle}
                      </a>
                    )}
                  </div>
                  <div className="col-12 col-md-7 popup-image">
                    <img src={imagepath + homedata.homedetail.popupimage}></img>
                    <a onClick={handleClose} className="close-Right-Top">
                      <i className="fa fa fa-times font-size-13"></i>
                    </a>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </Container>
  );
};

export default connect((state) => state)(Home);
